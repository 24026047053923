import React, { FC } from 'react';
import { Chip, Grid, useMediaQuery, useTheme } from '@mui/material';

interface ChildProps {
  label: string;
  values: number[];
  unit?: string;
}

const CompareMetricValue: FC<ChildProps> = ({ label, values, unit = '' }) => {

  let theme = useTheme();
  let colors = theme.colors;
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));

  return (
    //  Label Chip with Responsive Width
    <Grid container spacing={isXs ? 0.5 : 2} mb={0.5} wrap="nowrap">
      <Grid item xs={values.length == 3 ? 4.2 : 7} sm={4} lg={4} minWidth={isLg ? 330 : 0}>
        {/*TODO implement tooltip*/}
        {/*<Tooltip key={label + '-compareValue-tooltip' + index} title="Year 2022: 3.3 vs. 2023: 4.7" placement="left" arrow>*/}
        <Chip
          label={label}
          variant="filled"
          sx={{
            width: '100%',  // Let the grid handle the responsive width
            justifyContent: 'flex-start',
            fontWeight: 'bold',
            pl: 0.5
          }}
        />
      </Grid>

      {/* Value Chips with Responsive Width */}
      {values.map((value, index) => (
        <Grid key={label + '-compareValue' + index} item xs={values.length == 3 ? 2.7 : 5} sm={values.length == 3 ? 1.5 : 3} lg={1.5}>
          <Chip
            label={value !== -1 ? (value ? value.toFixed(isXs ? (value <= -100 || value >= 1000 ? 0 : 1) : 2) + unit : '') : 'n/a'}
            variant="filled"
            sx={{
              width: '100%',  // Let the grid handle the responsive width
              fontWeight: 'bold',
              bgcolor: index === 0
                ? colors.primary.light
                : index === 1
                  ? colors.info.light
                  : colors.warning.light
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CompareMetricValue;